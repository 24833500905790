<template>
  <div class="xa_cpb">
    <!-- <div class="dic_title">行政执法监督云平台</div> -->
    <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
    <div class="zcboxs">
      <h3 class="htitle">业务介绍</h3>
      <div class="ptext zfirst">
   以 “ 找堵防 ”工作“ 排 、研 、 交 、 办（处 ）、
结、考 ”六个方面的工作机制为基础，全面排查收集
各职能部门、基层组织发现掌握各类风险隐患窟窿信
息，并及时进行会商研判、分类交办、督促办理、落
实反馈，着力防范各类矛盾隐患发酵蔓延、扩大升级</div>
 <div class="imag">
        <img src="../../assets/images/jjfa/zdf.png" alt="" />
      </div>
      <h3 class="htitle">功能应用</h3>
      <div class="cars-fa">
        <el-row :gutter="40">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe62b;</i>排</div>
              <div class="mdess">
              矛盾风险相关责任人分
析研判并填报风险隐患
              </div>
            </div></el-col>
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe635;</i>研</div>
              <div class="mdess">
               相关责任领导\干部划分风  险等级(一级、二级、 三级)
              </div>
            </div></el-col >
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe636;</i>交</div>
              <div class="mdess">
               相关责任领导\干部确 定矛盾风险包保
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64c;</i>办</div>
              <div class="mdess">
               案时限 ，提前3天推送 给风险管控包保领导督办
              </div>
            </div></el-col
          >
           <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64c;</i>处</div>
              <div class="mdess">
              矛盾风险包保人负责 填报风险处置情况
              </div>
            </div></el-col>
         <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64c;</i>结</div>
              <div class="mdess">
               处置完成后 ，由矛盾风
险包保人提出结案
              </div>
            </div></el-col>
           
        </el-row>
      </div>
      <h3 class="htitle">典型案例</h3>
      <div class="anpics">
          <el-row :gutter="40">
          <el-col :span="12"><div class="grid-content bg-purples">
            <div class="mtitless">找堵防态势感知平台</div>
            <div class="mdess mdesss">平台可以精准展示管控人员、管控对象的地理分布及现 状、异常事件、关联异常人员、风险等级及处置情况。</div>
            </div></el-col>
          <el-col :span="12"><div class="grid-content bg-purpless">
              <div class="anboxes">
                <img src="../../assets/images/jjfa/zdf2.png" alt="" />
                </div>
            </div></el-col>
      </el-row>
      
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height: 230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.htitle {
  margin-bottom: 30px;
  font-size: 24px;
  text-indent: 2em;
  margin-top: 60px;
  position: relative;
  text-align: center;
}
.htitle::before{
  content:'';
  position: absolute;
  width:40px;
  height:4px;
  background:#004bb2;
  left:50%;
  margin-left:5px;
  bottom:-14px;
}
.ptext {
  text-indent: 2em;
  line-height: 28px;
  text-align: justify;
  margin-bottom: 20px;
}
.zcboxs {
  padding: 0 30px;
}
.imag {
  width: 50%;
  overflow: hidden;
  margin: 20px auto;
}
.imag img {
  width: 100%;
  height: auto;
}
.zfirst {
  margin-top: 50px;
  padding:0 80px;
}
.bg-purple {
  background: linear-gradient(360deg, rgba(147, 185, 253, 0) 0%, rgba(0, 79, 255, 0.03) 100%);
  padding: 30px;
  height: 230px;
  margin: 20px 0;
  border:1px #004bb2 solid;
  border-radius: 3px;
}
.mtitles {
  text-align: center;
  line-height: 60px;
  font-size: 18px;
}
.mdess {
  padding: 5px 0;
  line-height: 28px;
  font-size: 15px;
}
.icort{
  display: inline-block;
  margin-right:15px;
  font-size: 48px;
  color:#004bb2;
}
.cars-fa{
  width:90%;
  margin:0 auto;
}
.anboxes{
  width:100%;
  height:300px;
  overflow: hidden;
}
.anboxes image{
  width:100%;
  height:auto;
}
.anpics{
  margin-top:60px;
}
.mtitless{
  text-align: left;
  font-size: 22px;
  margin-bottom:20px;
}
.bg-purples{
  padding-left:80px;
}
.mdesss{
  font-size: 16px;
  text-align: justify;
}
</style>
