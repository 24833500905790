<template>
    <div class="xa_cpb">
        <!-- <div class="dic_title">行政执法监督云平台</div> -->
        <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
        <div class="zcboxs">
            <h3 class="htitle">业务介绍</h3>
            <div class="ptext zfirst">
                随着互联网技术的发展，人们的信息获取渠道越来越广泛，网络舆情也越来越复杂，舆情滋生与传播的渠道也越来越丰富。尤其是以短视频平台如抖音、快手，就成为了新的舆情传播主力之一。为切实快速获取和处置舆情负面信息，特研发此“舆情预警管控”系统。
            </div>
            <div class="imag">
                <img src="../../assets/images/jjfa/yqyjgk.jpg" alt="" />
            </div>
            <div class="ptext zfirst" style="margin:0;">
                系统使用互联网、大数据、移动端、AI智慧分析等技术手段，兼并公安、政法、职能单位、社会力量全面参于，对重点对象、重点部位、重大活动、突出事件进行随时随地监控，对可能发生的舆情早分析、早发现、早管控。充分利用社会面的智能终端，对政府、单位投入建设的视频资源进行有效补充，通过考核与奖励实现发动群众、依靠群众，真正打造成一张群防群治的立体化舆情防控网。解决被动发现、上报不便、定位不准、情况不明、情报难识别、研判管控不及时等一系列问题。
            </div>


            <!-- <h3 class="htitle">典型案例</h3> -->
            <div class="anpics" v-if="false">
                <el-row :gutter="40">
                    <el-col :span="24">
                        <div class="grid-content bg-purples">
                            <div class="mtitless">钟祥市治安要素管控平台</div>
                            <div class="mdess mdesss">系统通过管控工作要
                                求及包保关系，进行日常管控任务推送，对未按时
                                完成管控工作的， 系统自动推送督办任务给责任干
                                部进行督导。截止当前， 已在系统完成监护、 日常
                                管控(随访、重点时段管控)等管控工作4534项，对
                                未 及 时 完 成 的 管控任 务 ， 由 主 管 干部/民 警进 行督 导 ， 已 完 成督 导 工作 462项。</div>
                        </div>
                    </el-col>
                    <!-- <el-col :span="12"><div class="grid-content bg-purpless">
                <div class="anboxes">
                  <img src="../../assets/images/jjfa/xzzfs.png" alt="" />
                  </div>
              </div></el-col> -->
                </el-row>

            </div>
        </div>
    </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
    data() {
        return {
            dataInfo: {},
        };
    },
    created() { },
    mounted() {
        //this.getInfo()
    },
    methods: {
        //获取列表
        getInfo() {
            let data = {
                asId: 1,
            };
            postRequest(api.aboutUsId, data).then((res) => {
                this.dataInfo = res.data;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.xa_cpb {
    width: 100%;
    background: #ffffff;
    padding: 30px 0 80px;
}

.cpcard {
    width: 25%;
    margin-right: 25px;
    background: #ffffff;
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
    padding-bottom: 0;
}

.cpcard img {
    width: 100%;
    height: 100%;
}

.cpboxs {
    margin-top: 0;
}

.cpboxs .cpcard:last-of-type {
    margin-right: 0;
}

.cpcard_title {
    padding: 0;
    position: relative;
}

.cpcard_title i {
    position: absolute;
    left: 0;
    top: 43px;
}

.cpcard_title .cpcard_t0 {
    font-size: 18px;
    color: #17181b;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid rgba(0, 11, 23, 0.06);
    padding-bottom: 19px;
    padding-left: 40px;
}

.cp_contitle {
    font-size: 15px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 15px;
}

.cp_ctext {
    font-size: 14px;
    color: #808082;
    line-height: 24px;
    background: #ffffff;
    height: 230px;
}

.cpcard-des {
    border: 1px solid rgba(0, 113, 239, 0.7);
    color: #0071ef;
    font-size: 12px;
    display: inline-block;
    padding-left: 3.5px;
    padding-right: 3.5px;
    border-radius: 3px;
    height: 20px;
    text-align: center;
    line-height: 18px;
    vertical-align: middle;
    margin-left: 5px;
}

.btns1 {
    height: 36px;
    width: 90px;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
    background-color: #0071ef;
    margin-right: 24px;
    margin-top: 30px;
}

.cp_btns {
    opacity: 0;
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    // -webkit-transition: all .3s ease-in-out;
    // transition: all .3s ease-in-out;
    height: 62px;
    display: flex;
    justify-content: center;
}

.bt02 {
    background: none;
    border: 1px #ffffff solid;
    color: #ffffff;
}

.cpcard {
    overflow: hidden;
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
    height: 280px;
    overflow: hidden;
    box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpboxs2 .cpcard:hover {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
}

.cpcard:hover {
    height: 296px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transform: translateY(-4px);
}

.cpboxs2 .cpcard:hover {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpcard:hover .cp_btns {
    opacity: 1;
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpcard:hover .cp_ctext {
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpcard_title {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpcard:hover .cpcard_title {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpcard:hover .cp_ctext {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cpboxs {
    height: 306px;
}

.itemnamse {
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.htitle {
    margin-bottom: 30px;
    font-size: 24px;
    text-indent: 2em;
    margin-top: 60px;
    position: relative;
    text-align: center;
}

.htitle::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 4px;
    background: #004bb2;
    left: 50%;
    margin-left: 5px;
    bottom: -14px;
}

.ptext {
    text-indent: 2em;
    line-height: 28px;
    text-align: justify;
    margin-bottom: 20px;
}

.zcboxs {
    padding: 0 30px;
}

.imag {
    width: 80%;
    overflow: hidden;
    margin: 20px auto;
}

.imag img {
    width: 100%;
    height: auto;
}

.zfirst {
    margin-top: 50px;
    padding: 0 80px;
}

.bg-purple {
    background: linear-gradient(360deg, rgba(147, 185, 253, 0) 0%, rgba(0, 79, 255, 0.03) 100%);
    padding: 30px;
    height: 230px;
    margin: 20px 0;
    border: 1px #004bb2 solid;
    border-radius: 3px;
}

.mtitles {
    text-align: center;
    line-height: 60px;
    font-size: 18px;
}

.mdess {
    padding: 5px 0;
    line-height: 28px;
    font-size: 15px;
}

.icort {
    display: inline-block;
    margin-right: 15px;
    font-size: 48px;
    color: #004bb2;
}

.cars-fa {
    width: 90%;
    margin: 0 auto;
}

.anboxes {
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.anboxes image {
    width: 100%;
    height: auto;
}

.anpics {
    margin-top: 60px;
}

.mtitless {
    text-align: left;
    font-size: 22px;
    margin-bottom: 20px;
}

.bg-purples {
    padding-left: 80px;
}

.mdesss {
    font-size: 16px;
    text-align: justify;
}
</style>