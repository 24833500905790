<template>
  <div class="bodycont">
    <comHeader />
    <div class="aboutbanner">
      <div class="atets" v-if="on==1">行政执法监督解决方案</div>
       <div class="atets" v-if="on==2">交通管理递进式执法解决方案</div>
       
       <div class="atets" v-if="on==3">治安要素管控解决方案</div>
        <div class="atets" v-if="on==4">舆情预警管控</div>
       <div class="atets" v-if="on==5">找堵防管控</div>
        <!-- <div class="atets" v-if="on==6">流动人口管控</div> -->
        <div class="atets" v-if="on==11">基础管控中心</div>

       <div class="atets" v-if="on==7">电力营销系统</div>
       <div class="atets" v-if="on==8">电能计量系统</div>
       <div class="atets" v-if="on==9">自助服务终端</div>
       <div class="atets" v-if="on==10">电力三防</div>
       <div class="atets" v-if="on==12">电力大数据</div>
    </div>
    <div class="indexMinker indexMinkerSd" style="padding: 25px 0;">
    <div class="cont w1146">
      <div class="dic_inner dic_flex diccup">
        <div class="aboutNav" style="display:none;">
          <a href="#" :class="[{'on':on==1}]" rel="nofollow" @click="change(1)">行政执法监督</a>
          <a href="#" :class="[{'on':on==2}]" rel="nofollow" @click="change(2)">治安要素</a>
          <a href="#" :class="[{'on':on==3}]" rel="nofollow" @click="change(3)">交通管控</a>
          <a href="#" :class="[{'on':on==4}]" rel="nofollow" @click="change(4)">找堵防</a>
          <a href="#" :class="[{'on':on==5}]" rel="nofollow" @click="change(5)">舆情管控</a>
          <a href="#" :class="[{'on':on==6}]" rel="nofollow" @click="change(6)">流动人口管控</a>
        </div>
        <div class="aboutctiv">
          <div v-if="on==1"> <xzzf /></div>
           <div v-else-if="on==2"> <jtzf /></div>
           <div v-else-if="on==3"> <zays /></div>
           <div v-else-if="on==4"> <yqyjgk /></div>
           <div v-else-if="on==5"> <zdf /></div>
           <div v-else-if="on==7"> <dwyxxt /></div>
           <div v-else-if="on==8"> <dwdnxt /></div>
           <div v-else-if="on==9"> <dwzdxt /></div>
           <div v-else-if="on==11"> <jcgkzx /></div>
           <div v-else-if="on==12"> <dldsj /></div>
           <div v-else> <Error /></div>
        </div>
  
    </div>
    </div>
</div>
   <indexFoot />
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import classifyType from '@/components/classify.vue'
  import navTitle from '@/components/navtitle.vue'
  import indexFoot from '@/components/indexfoot.vue'
  import xzzf from '@/components/case/xzzf'
  
  import jtzf from '@/components/case/jtzf'
  import zays from '@/components/case/zays'
   import zdf from '@/components/case/zdf'
  import dwyxxt from '@/components/case/dwyxxt'
  import dwdnxt from '@/components/case/dwdnxt'
  import dwzdxt from '@/components/case/dwzdxt'
  //基础管控中心
  import jcgkzx from '@/components/case/jcgkzx'
  import yqyjgk from '@/components/case/yqyjgk'
  import dldsj from '@/components/case/dldsj'
  import Error from '@/components/case/empty'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      classifyType,
      navTitle,
      indexFoot,
      xzzf,
      jtzf,
      zdf,
      zays,
      dwyxxt,
      dwdnxt, 
      dwzdxt,
      jcgkzx,
      yqyjgk,
      dldsj,
      Error
    },
    data() {
      return {
          on:1
      }
    },
    created() {
        console.log(this.$route)
        this.on = this.$route.params.id
    },
    watch:{
      '$route.params.id':function(n,o){
        this.on = n
      }
    },
    mounted() {

    },
    methods:{
      change(item){
        this.on = item
      }
    }
  }
</script>
<style lang="less" scoped>
.bodycont{
  background:#f5f7fa;
}
  .aboutbanner{
    width:100%;
    height:200px;
    background: url(../../assets/images/bj.jpg) no-repeat center center;
    background-size: cover;
  }
  .aboutNav{
    margin-top:20px;
    width:25%;
  }
  .contsbox{
    padding:40px 0;
    display: none;
    min-height: 400px;
  }
  .contsbox.active{
    display: block;
  }
  .atitle{
    text-align: center;
    font-size: 28px;
  }
  p{
    line-height: 28px;
    text-indent: 2em;
    margin-top:20px;
    color:#666;
    text-align: justify;
    font-size: 14px;
  }
  .flx_align{
    align-items: center;
    justify-content: space-around;
  }
  .apbox{
    margin-right:20px;
    width:700px;
  }
  .atitle{
    margin-bottom:40px;
    margin-top:60px;
  }
  .on{
    color: #ffffff !important;
    background: #114BA3;
  }
  .dd{
    width:500px;
    height:286px;
    overflow:hidden;
  }
  .dd img{
    width:100%;
    height:auto;
  }
  .dt{
    margin-top:30px;
    font-size:18px;
  }
  .ds{
    font-size: 16px;
    margin-top:15px;
  }
  .atets{
    width:1280px;
    margin:0 auto;
     font-family: "WHDICFONT" !important;
    color:#ffffff;
    font-size:36px;
    padding-top:120px;
  }
  .aboutNav{
    background: #ffffff;
    min-height: 800px;
  }
  .aboutNav a{
    display: block;
    height:60px;
    line-height: 60px;
    padding-left:30px;
    margin-bottom:30px;
   
  }
  .aboutctiv{
    width:100%;
    margin-left: 30px;
    margin-top:20px;
  }
  .diccup{
    justify-content: space-between;
  }
</style>
