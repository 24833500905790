<template>
  <div class="xa_cpb">
    <div class="dic_title">售电云</div>
    <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
    <div class="zcboxs">
      <div class="zcboxst-box bggrey">
        <h3 class="htitle"></h3>
        <div  class="ptext">基于计算机、网络通信、IoT、云计算、大数据等数字信息处理技术，
            以售电管理及电力市场交易为服务对象的综合智能运营一体化管理云平台，涵盖电力营销(购售电)、远程电量采集、配电运维、客户服务等内容。</div>
          <!-- <div class="imag"> <img src="../../assets/images/jjfa/show01.png" alt="" /></div> -->
        
   
    <!-- <h3  class="htitle">主要功能</h3> -->
    <div class="ptextt">
            <div class="ptyxbox">
              <div class="ptyxobxtext">
                <span>系统主要功能</span></div>
                <ul class="yxitems">
                          <li>1.购电管理</li> 
                          <li>2.综合档案管理</li>
                          <li>3.用电合同管理</li>
                          <li>4.抄表管理</li>
                          <li>5.远程电量采集</li>
                          <li>6.电费核算管理</li>
                          <li>7.电费结算管理</li>
                          <li>8.远程费控</li>
                          <li>9.设备资产管理</li>
                          <li>10.配电运维管理</li>
                          <li>11.能源合同管理</li>
                          <li>12.用电客户服务</li>
                          <li>13.掌上营业厅/移动应用</li>
                          <li>14.市场需求及拓展</li>
                </ul>
            </div>

  </div>
   </div>
   </div>
  <div class="bggrenn">
  <h3  class="htitle">产品特点</h3>
  <div class="ptextot">
    <ul class="ptuls">
      <li>
        <div class="iconfont ictrs">&#xe994;</div>
        <div class="sboxst">一键式部署</div>
         <div class="sboxs">一键式云部署，拎包入驻</div>
      </li>
      <li>
         <div class="iconfont ictrs">&#xe997;</div>
        <div class="sboxst">一站式服务</div>
         <div class="sboxs">一站式管家服务，无需配备专业技术人员</div>
      </li>
       <li>
          <div class="iconfont ictrs">&#xe99b;</div>
        <div class="sboxst">快速搭建</div>
         <div class="sboxs">简单订购，快速搭建企业级应用</div>
      </li>
       <li>
          <div class="iconfont ictrs">&#xe9a0;</div>
        <div class="sboxst">体系完善</div>
         <div class="sboxs">服务体系完善，客户体验良好</div>
      </li>
       <li>
          <div class="iconfont ictrs">&#xe9ab;</div>
        <div class="sboxst">零门槛</div>
         <div class="sboxs">零门槛，降低初期IT投入成本及实施风险</div>
      </li>
    </ul>
   </div> 
   </div>
  <h3  class="htitle">我们的优势</h3>
  <div class="ptextpts ptextptsflex">
    <div class="pt_right">
        <img src="../../assets/images/jjfa/quan01.png" alt="" />
     </div>
    <div class="pt_left">
        <div class="ptys-item">
          具有一直团结、专业、踏实、稳定的专业团队
        </div>
        <div class="ptys-item">
          具有20年的电力信息化建设经验，有一套完善的产品体系和技术服务体系
        </div>
        <div class="ptys-item">
          一直致力于高效、实用、经济的电力营配信息化平台建设，拥有多项自主专利及知识产权
        </div>
        <div class="ptys-item">
        紧跟时代步伐，不断运用成功经验和新技术，为售电企业提供专业化、基于云平台的信息化服务
        </div>
    </div>
     
  </div> 
  <div class="">
  <h3  class="htitle">典型客户</h3>
  <div class="ptbox-kh">
    <ul>		
      <li>广东电网公司</li>
      <li>宁夏电力公司</li>
      <li>福建电力公司</li>
      <li>新疆建设兵团</li>
      <li>山东电力公司</li> <li>湖南郴电国际</li><li>汉江集团公司</li><li>重庆电网公司</li>
      <li>广西水利电业集团</li> <li>航天一院</li><li>四川电网公司</li><li>四川水电集团</li>
      <li>连云港工投集团</li><li>北京市电力公司</li><li>内蒙古电力集团有限责任公司</li><li>中国民航大学</li>
      <li>湖北电力公司</li> <li>陕西水电集团有限公司</li><li>海南南山电厂</li>
      <li>贵州电网公司</li> <li>云南电网公司</li><li>广西电网公司</li><li>海南电网公司</li><li>广东省广州供电局</li>
    </ul>
  </div> 
  </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height:230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.htitle{
  margin-bottom:50px;
  font-size:22px;
  text-indent: 2em;
  margin-top:80px;
  font-weight: bold;
  text-align: center;
}
.ptext{
  text-indent: 2em;
  line-height:28px;
  text-align: justify;
  margin-bottom:20px;
}
.zcboxs{
  // padding:0 30px;
}
.imag {
  width: 60%;
  overflow: hidden;
  margin: 20px auto;
}
.imag img {
  width: 100%;
  height: auto;
}
.yxitems{
  // background: #f8f8f8;
  // padding:20px 0;
  
}
.yxitems li{
  width:33%;
  float:left;
  clear: right;
  padding:30px 60px;
  border:1px #e5e5e5 dashed;
  margin-left:-1px;
  margin-bottom:-1px;
  text-align: center;
}
.ptyxbox{
  display: flex;
  margin:60px auto;
 
}
.ptyxobxtext{
 width:40px;
 text-align: center;
 padding:0 10px;
 background: #4ec86c;
 color:#ffffff;
}
.ptyxobxtext{
  display: flex;
  align-items: center;
}
.ptuls li{
  float:left;
  width:33.3%;
  text-align: center;
  margin-bottom:55px;
}
.ptuls{
  overflow: hidden;
}
.sboxs{
 text-align: center;  
 margin:15px 0;
 margin-top:25px;
}
.sboxst{
 text-align: center;  
 margin:15px 0;
 font-weight: bold;
 margin-top:25px;
}
.ictrs{
  font-size:34px;
  border:1px #4ec86c solid;
  border-radius: 50%;
  width:80px;
  height:80px;
  text-align: center;
  line-height: 80px;
  margin:0 auto;
  color:#4ec86c;
}
.ptextot{
  margin:30px auto;
}
.bggrenn{
  background: #f3f8f4;
  padding:25px 60px;
}
.bggrey{
  background: #ffffff;
  padding:25px 60px;
}
.zcboxst-box{
  
}
.ptextptsflex{
  display: flex;
   width:80%;
   margin:20px auto;
    align-items: center;
    margin-top:80px;
}
.ptys-item{
   margin-bottom:25px;
   line-height: 32px;
}
.ptbox-kh{
  width:80%;
  margin:0 auto;
}
.ptbox-kh ul{
  overflow: hidden;
}
.ptbox-kh li{
  width:25%;
  float:left;
  text-align: center;
  border:1px #f8f8f8 dashed;
  height:65px;
  line-height: 65px;
  color:#666;
}
</style>
