<template>
  <div class="xa_cpb">
    <div class="dic_title">电能计量系统</div>
    <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
    <div class="zcboxs">
      <h3 class="htitle"></h3>
      <div  class="ptext">为发电厂、变电站、开关站等电能汇聚交换关口进行实时电能采集、展示、分析，支持分布式部署及数据汇聚，支持多通信规约集成，支持多厂家终端设备，形成统一的管控流程和数据接口，为区域电力运行监控、故障发现及分析提供强有力的支持。</div>
    <h3  class="htitle">主要功能</h3>
    <div   class="pdn-list">
      <div class="dn-list"><div class="dn-items">档案管理</div>电网设备档案，计量关口基本档案、电源点、计量点、电能表、互感器，采集终端等档案信息管理。</div>
      <div class="dn-list"><div class="dn-items">电能数据采集</div>支持多种通信规约，支持定时采集、人工补采，采集规约 支持的电能数据、瞬时量数据，包括数据辨析、数据规整、分包入库、数据异常分析等功能。</div>
      <div class="dn-list"><div class="dn-items">表计变更</div>换表、换CT、录入表码管理。</div>
      <div class="dn-list"><div class="dn-items">电能数据计算</div>包括自动计算和手动计算方式，包括电量计算、线损计算、瞬时量计算、极值计算等。</div>
      <div class="dn-list"><div class="dn-items">数据查询</div>基础数据查询，实时数据查询，电量数据查询，事件查询等。</div>
      <div class="dn-list"><div class="dn-items">汇总分析</div>电量生产日报，结合应用场景的统计、分析报表。</div>
      <div class="dn-list">
          <div class="dn-items">系统管理</div>组织机构管理，操作员管理，角色权限管理，菜单管理，数据字典管理，日志管理等。</div>
      </div>
   
    <h3  class="htitle">产品架构</h3>
    <div   class="ptexts">
      <div class="imag"> 
        <img src="../../assets/images/jjfa/dnjg.png" alt="" />
      </div>
    </div> 
     <h3  class="htitle">特色亮点</h3>
  <div   class="ptext">
    <!-- <div class="ptitems01">多规约集成，标准规范，适应性强</div>
    <div class="ptitems01">部署快捷，安装调试方便</div>
    <div class="ptitems01">支持分布式，满足多应用场景</div>
    <div class="ptitems01">根据应用需求定制报表，满足个性化需求</div> -->
     <div class="imags"> 
      <img src="../../assets/images/jjfa/gy1.png" alt="" />
    </div>
  </div> 
     <h3  class="htitle">部分业务界面</h3>
  <div  class="ptext">
    <div class="imags"> 
      <img src="../../assets/images/jjfa/webs.png" alt="" />
    </div>
  </div> 
   </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height:230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.htitle{
  margin-bottom:30px;
  font-size:22px;
  margin-top:80px;
  font-weight: bold;
  text-align: center;
}
.ptext{
  text-indent: 2em;
  line-height:28px;
  text-align: justify;
  margin-bottom:20px;
}
.zcboxs{
  padding:0 30px;
}
.pdn-list{
  overflow: hidden;
}
.dn-list{
  width:30.7%;
  float:left;
  background: #f3f8f4;
  padding:10px 25px;
  height:240px;
  margin:15px;
  line-height: 32px;
  color:#5f5956;
  border-radius: 4px;
}
.dn-items{
  text-align: center;
  margin:15px auto;
  font-weight: bold;
  color:#141d23;
}
.ptexts{
  width:80%;
  margin:35px auto;
}
.imag{
  margin-top:100px;
}
.imag image{
  width:100%;
  height:auto;
}
.imags{
  text-align: center;
}
</style>
