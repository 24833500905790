<template>
  <div class="xa_cpb">
    <!-- <div class="dic_title">行政执法监督云平台</div> -->
    <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
    <div class="zcboxs">
      <h3 class="htitle">业务介绍</h3>
      <div class="ptext zfirst">
     以治安管理的 “ 重点人 ”和 “ 重要 阵地 ”为对象 ，结合信息化先进技术 ， 融合 常 态工作 的信 息采集 、 闭环工作流 程机制 、数据分析与资源整合呈现 、数 据碰撞预警 、跟踪督办责任压实等技术
要求 。 以公安队伍为依托，将村/社区、乡镇/街道综治力量及相关专业部门人员纳入管控体系，壮大治安管控力量，形成社会治理大闭环。研发了“找堵防 ”管控、精障
患者管控、涉毒人员管控、涉稳人员管控、社区矫正管控、刑释安置帮教、重点场所管控、民爆安全管控等小程序，在互联网上搭建代
理访 问服务器及数据 审计服务器 ，应对与前端移动应用服务 ，通过网络安全边界，与服务层实现定向访问，最终实现数据内外网的安全传输。</div>
 <div class="imag">
        <img src="../../assets/images/jjfa/zays1.png" alt="" />
      </div>
      <h3 class="htitle">依托平台   实现八大目标</h3>
      <div class="cars-fa">
        <el-row :gutter="40">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe62b;</i>底数清楚</div>
              <div class="mdess">
               实时、真实展示患者总人数、人户分离人数、住院收治人数，异常处置人数、外出、迁移、失访，终止人数，病情稳定与不稳定人数等，一目了然。
              </div>
            </div></el-col>
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe635;</i>状态可知</div>
              <div class="mdess">
               实时更新相关人员随访、回访、重点人员出入院、监护人日常监护、事件上报，位置上传等状态信息，实现人员状态的全域可知，做到心中有数。
              </div>
            </div></el-col >
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe636;</i>精准预警</div>
              <div class="mdess">
                平台联动公安人脸库，在重点区域进行人脸布控，当重点人员出现后，平台第一时间发送预警，相关单位及人员会第一时间作出响应。
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe637;</i>工作提醒</div>
              <div class="mdess">
                将责任人员的工作情况、工作职责任务菜单化，方便使用者明确自己的任务，以信息推送的方式对没完成任务的人员会第一时间做出响应。
              </div>
            </div></el-col
          >
         <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe663;</i>网上流转</div>
              <div class="mdess">
               将所有需要在部门间流转的功能尽可能网上审批流转，使流转更方便快捷，全程留痕，特别是在收治审批中让群众少跑腿，让数据多跑路。
              </div>
            </div></el-col>
             <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64c;</i>信息共享</div>
              <div class="mdess">
               任何一个部门在平台上更新数据后，有管理职责的其它人员均可看到最新数据，实现数据实时共享。
              </div>
            </div></el-col>
             <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64b;</i>责任明确</div>
              <div class="mdess">
              平台对各部门职责任务进行细化，清单化，明确责任到岗、责任到人，强化监督，将被动履责转变为主动管理，实现对重点人员的无缝化管理。
              </div>
            </div></el-col>
              <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe650;</i>部门联动</div>
              <div class="mdess">
              整合公安、人社、民政以及社区等多方面的数据资源，及时流转，确保从发现到核查、从应急到预防、从处置到收治、从列管到帮扶无死角。
              </div>
            </div></el-col>
        </el-row>
      </div>
      <h3 class="htitle">典型案例</h3>
      <div class="anpics">
          <el-row :gutter="40">
          <el-col :span="24"><div class="grid-content bg-purples">
            <div class="mtitless">钟祥市治安要素管控平台</div>
            <div class="mdess mdesss">系统通过管控工作要
求及包保关系，进行日常管控任务推送，对未按时
完成管控工作的， 系统自动推送督办任务给责任干
部进行督导。截止当前， 已在系统完成监护、 日常
管控(随访、重点时段管控)等管控工作4534项，对
未 及 时 完 成 的 管控任 务 ， 由 主 管 干部/民 警进 行督 导 ， 已 完 成督 导 工作 462项。</div>
            </div></el-col>
          <!-- <el-col :span="12"><div class="grid-content bg-purpless">
              <div class="anboxes">
                <img src="../../assets/images/jjfa/xzzfs.png" alt="" />
                </div>
            </div></el-col> -->
      </el-row>
      
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height: 230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.htitle {
  margin-bottom: 30px;
  font-size: 24px;
  text-indent: 2em;
  margin-top: 60px;
  position: relative;
  text-align: center;
}
.htitle::before{
  content:'';
  position: absolute;
  width:40px;
  height:4px;
  background:#004bb2;
  left:50%;
  margin-left:5px;
  bottom:-14px;
}
.ptext {
  text-indent: 2em;
  line-height: 28px;
  text-align: justify;
  margin-bottom: 20px;
}
.zcboxs {
  padding: 0 30px;
}
.imag {
  width: 80%;
  overflow: hidden;
  margin: 20px auto;
}
.imag img {
  width: 100%;
  height: auto;
}
.zfirst {
  margin-top: 50px;
  padding:0 80px;
}
.bg-purple {
  background: linear-gradient(360deg, rgba(147, 185, 253, 0) 0%, rgba(0, 79, 255, 0.03) 100%);
  padding: 30px;
  height: 230px;
  margin: 20px 0;
  border:1px #004bb2 solid;
  border-radius: 3px;
}
.mtitles {
  text-align: center;
  line-height: 60px;
  font-size: 18px;
}
.mdess {
  padding: 5px 0;
  line-height: 28px;
  font-size: 15px;
}
.icort{
  display: inline-block;
  margin-right:15px;
  font-size: 48px;
  color:#004bb2;
}
.cars-fa{
  width:90%;
  margin:0 auto;
}
.anboxes{
  width:100%;
  height:300px;
  overflow: hidden;
}
.anboxes image{
  width:100%;
  height:auto;
}
.anpics{
  margin-top:60px;
}
.mtitless{
  text-align: left;
  font-size: 22px;
  margin-bottom:20px;
}
.bg-purples{
  padding-left:80px;
}
.mdesss{
  font-size: 16px;
  text-align: justify;
}
</style>
