<template>
  <div class="xa_cpb">
    <div class="dic_title">自助服务终端</div>
    <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
    <div class="zcboxs">
      <h3 class="htitle"></h3>
      <div  class="ptext" style="width:80%;margin:0 auto;">自助服务终端产品能够在无人值守的情况下，提供24小时客户自助服务，客户可以在自助服务终端上轻松、便捷、及时的获得电/水/气费查询、缴费和卡表/远程充值、票据打印、综合信息查询、业务申请、业务查询等服务，同时服务企业也可以把自助终端作为一个宣传的窗口，发布企业简介、业务办理、业务通告(停电/水/气通告、价格调整、服务信息通告等)、现行电/水/气价表、缴费指南、服务指南、服务承诺及免责声明、营业网点分布情况、客户咨询与投诉热线等信息，可与掌上营业厅形成完整的线上/线下服务融合。</div>
    <h3  class="htitle">主要功能</h3>
    <div   class="pdn-list">
      <div class="dn-list"><div class="dn-items">信息查询</div>提供客户基本档案、电费信息、缴费信息、欠费信息等相关信息查询</div>
      <div class="dn-list"><div class="dn-items">自助缴费</div>可办理现金/银联卡/扫码缴费、预存、凭条打印等服务</div>
      <div class="dn-list"><div class="dn-items">自助购电/水/气</div>提供现金卡表/银联卡/扫码购电/水/气、凭条打印等服务</div>
      <div class="dn-list"><div class="dn-items">业务办理</div>可预约办理业务、办理流程查询、资料投递、投诉建议等</div>
      <div class="dn-list"><div class="dn-items">后台管理</div>提供终端管理、代办点管理、对账管理、报表查询、系统维护等功能</div>
      <div class="dn-list">
          <div class="dn-items">系统管理</div>组织机构管理，操作员管理，角色权限管理，菜单管理，数据字典管理，日志管理等。</div>
      </div>
   
    <h3  class="htitle">产品架构</h3>
    <div   class="ptexts" style="margin-top:0;">
      <div class="imag"> 
        <img src="../../assets/images/jjfa/zjjg.png" alt="" />
      </div>
    </div> 
     <h3  class="htitle">特色亮点</h3>
  <div   class="ptext">
    <!-- <div class="ptitems01">多规约集成，标准规范，适应性强</div>
    <div class="ptitems01">部署快捷，安装调试方便</div>
    <div class="ptitems01">支持分布式，满足多应用场景</div>
    <div class="ptitems01">根据应用需求定制报表，满足个性化需求</div> -->
     <div class="imags" style="background:#f3f5f8;"> 
      <img src="../../assets/images/jjfa/webs09.png" alt="" />
    </div>
  </div> 
       <h3  class="htitle">产品外观</h3>
  <div  class="ptext">
    <div class="imags" style="text-align:center;"> 
      <img src="../../assets/images/jjfa/webs071.png" style="width:90%;" alt="" />
    </div>
  </div> 
     <h3  class="htitle">典型用户</h3>
  <div  class="ptext">
    <div class="imags"> 
      <img src="../../assets/images/jjfa/webst3.png" alt="" />
    </div>
  </div> 
   </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height:230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.htitle{
  margin-bottom:30px;
  font-size:22px;
  margin-top:80px;
  font-weight: bold;
  text-align: center;
}
.ptext{
  text-indent: 2em;
  line-height:28px;
  text-align: justify;
  margin-bottom:20px;
}
.zcboxs{
  padding:0;
}
.pdn-list{
  overflow: hidden;
  width:80%;
  margin:0 auto;
}
.dn-list{
  width:30%;
  float:left;
  background: #f3f8f4;
  padding:10px 25px;
  height:240px;
  margin:15px;
  line-height: 32px;
  color:#5f5956;
  border-radius: 4px;
}
.dn-items{
  text-align: center;
  margin:15px auto;
  font-weight: bold;
  color:#141d23;
}
.ptexts{
  width:80%;
  margin:35px auto;
}
.imag{
  margin-top:50px;
  text-align: center;
}
.imag image{
  width:100%;
  height:auto;
}
.imags{
  text-align: center;
}
</style>
